import { NgxLoggerLevel } from "ngx-logger";
import { Environment } from "../models";

export const environment: Environment = {
  app: "cinnux",
  appExecutive: "Opgelucht",
  production: true,
  logLevel: NgxLoggerLevel.OFF,
  serverLogLevel: NgxLoggerLevel.ERROR,
  fallback: "chat" as const,
  appLogo: "assets/img/opgelucht-logo.png",
  storageUrl: "https://cms-demo.cinnux.com/storage/",
  socketUrl: "https://socket-demo.cinnux.com/",
  socketPath: "/socket-chat",
  passphrase: "1234",
  allowAwayMessage: true,
  languages: ["nl", "en", "de"],
  firebase: {
    apiKey: "AIzaSyDv-ooj49YaWW0P4X3tiIwqYspOPvHf43I",
    authDomain: "cinnux-ec90b.firebaseapp.com",
    databaseURL: "https://cinnux-ec90b.firebaseio.com",
    projectId: "cinnux-ec90b",
    storageBucket: "cinnux-ec90b.appspot.com",
    messagingSenderId: "103216955993",
    appId: "1:103216955993:web:295228694baf85bf12e748",
  },
};
